import ArticleTile from "../ContentTiles/ArticleTile";
import { PlaylistTile } from "../ContentTiles/PlaylistTile";
import { VideoTile } from "../ContentTiles/VideoTile";
import "./ContentGrid.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { MediaType, CollectionType, ApiAsset } from "../../utils/ApiTypes";
import { TopicTile } from "../ContentTiles/TopicTile";
import { GenericTile } from "../ContentTiles/GenericTile";


interface Props {
    content: ApiAsset[];
    showPlaylistTitle?: boolean;
    showPlaylistDescription?: boolean;
    showVideoTitle?: boolean;
    showVideoDescription?: boolean;
    playlistId?: number;
    className?: string;
    contentHeader?: string;
    maxItems?: number;
    showButton?: boolean;
    handleClickSeeButton?: (seeAll: boolean) => void;
}

const ContentGrid: React.FC<Props> = (props) => {
    const { content, showPlaylistTitle, showPlaylistDescription, showVideoTitle, showVideoDescription, contentHeader } = props;
    const { t } = useTranslation();
    const [maxItems, setMaxItems] = useState<number | undefined | null>(props.maxItems);
    //Whenever user clicks all, we will override props and show title and descriptions
    const [showContentDetails, setShowContentDetails] = useState<boolean>(false);

    useEffect(() => {
        setMaxItems(props.maxItems);
    }, [maxItems, props.maxItems]);

    function handleSeeButton(){
        const seeAll: boolean = !!maxItems;
        setShowContentDetails(!showContentDetails);
        props.handleClickSeeButton && props.handleClickSeeButton(seeAll);
    }

    return (
        <div className={`content-grid-component ${props.className}`}>
            <div className={`h6-bold grid-header`}>{contentHeader} 
            {props.showButton && <a className="see-all-button button-link" onClick={handleSeeButton} >{maxItems ? t("see all") : t("see less")}</a>}
            </div>
            <div className={`content-grid-container ${(showContentDetails|| showPlaylistDescription || showVideoDescription) && "bigger-row-gap"}`}>
                {content.map((item: any, i: number) => {
                    if(maxItems && i >= maxItems) return null;
                    switch (item.type) {
                        case "playlist":
                            return <div key={i} className='content-tile'><PlaylistTile playlist={item} showTitle={showPlaylistTitle || showContentDetails} showDescription={showPlaylistDescription || showContentDetails}/></div>
                        case MediaType.Video:
                            return <div key={i} className='content-tile'><VideoTile video={item} showTitle={showVideoTitle || showContentDetails} showDescription={showVideoDescription || showContentDetails} playlistId={props.playlistId}/></div>
                        case MediaType.Audio:
                            return <div key={i} className='content-tile'><VideoTile video={item} showTitle={showVideoTitle || showContentDetails} showDescription={showVideoDescription || showContentDetails} playlistId={props.playlistId}/></div>
                        case MediaType.Article:
                            return <div key={i} className='content-tile'><ArticleTile item={item} /></div>
                        case 'wpocontent':
                            //TODO: Remove this case when we have proper content type
                            return <div key={i} className='content-tile'><ArticleTile item={item} /></div>
                        case CollectionType.Topic:
                            return <div key={i} className='content-tile'><TopicTile topic={item} /></div>
                        default:
                            return <div key={i} className='content-tile'><GenericTile title={item.title} path={item.path}/></div>
                    }
                })}
            </div>
        </div>
    );
};

export default ContentGrid;